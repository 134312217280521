import LocalConstants from "./views/Reagent/Constants";

export const LIST_TEST_METHOD = [
  { id: 1, name: "Elisa 5 chứng" },
  { id: 2, name: "Elisa 6 chứng" },
  { id: 3, name: "Ngưng kết hạt" },
  { id: 4, name: "Test nhanh" },
  { id: 5, name: "Điện hóa phát quang" },
  { id: 6, name: "Western Blot" },
];

export const EQA_RESULT_REPORT_TYPE_METHOD = [
  {
    value: LocalConstants.EQAResultReportTypeMethod.Elisa,
    name: "Elisa",
  },
  {
    value: LocalConstants.EQAResultReportTypeMethod.FastTest,
    name: "Test nhanh",
  },
  {
    value: LocalConstants.EQAResultReportTypeMethod.ECL,
    name: "Điện hóa phát quang",
  },
  {
    value: LocalConstants.EQAResultReportTypeMethod.SERODIA,
    name: "Serodia",
  },
];

export const GENDERS = [
  { id: "1", name: "Nam" },
  { id: "0", name: "Nữ" },
  { id: "2", name: "Không rõ" },
];

export const DIAGNOSTIC_STATUS_LIST = [
  { id: -1, name: "Âm tính" },
  { id: 0, name: "Không xác định" },
  { id: 1, name: "Dương tính" },
];
export const RESULTS_NIHE = [
  { id: "Âm tính", name: "Âm tính" },
  { id: "Không xác định", name: "Không xác định" },
  { id: "Dương tính", name: "Dương tính" },
];

export const ARV_HISTORY_LIST = [
  { id: 1, name: "Đang điều trị" },
  { id: 0, name: "Chưa điều trị" },
];

export const SAMPLE_LIST = [
  { id: 1, name: "Huyết thanh" },
  { id: 2, name: "Huyết tương" },
  { id: 3, name: "Máu toàn phần không chất chống đông" },
  { id: 4, name: "Máu toàn phần với EDTA/Citrate" },
  { id: 5, name: "Buffy Coat" },
];

export const SHIPPING_TEMPERATURE_LIST = [
  { id: 1, name: "Nhiệt độ phòng" },
  { id: 2, name: "Lạnh (2 - 10C)" },
  { id: 3, name: "Đá khô" },
];

export const MEANS_OF_TRANSPORT_LIST = [
  { id: 1, name: "Đường bộ" },
  { id: 2, name: "Hàng không" },
];

export const SERUM_TUBE_SET_TYPE_OF = {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
    five: 5,
}

export const BOX_RESULT_LIST = [
  { id: -1, name: "Box âm" },
  { id: 1, name: "Box dương" },
];

export const ROW_CODE_LIST = {
    rowZero: 0,
    rowOne: 1,
    rowTwo: 2,
    rowThree: 3,
    rowFour: 4,
    rowFive: 5,
    rowSix: 6,
    rowSeven: 7,
    rowEight: 8,
    rowNine: 9,
    rowTen: 10,
};

export const SERUM_TYPE_LIST = [
  { id: 1, name: "Huyết thanh" },
  { id: 2, name: "Huyết tương" },
  { id: 3, name: "Máu không có chất chống đông máu" },
  { id: 4, name: "Máu với EDTA/Citrate" },
  { id: 5, name: "Buffy Coat" },
];

export const TUBE_TYPE_LIST = [
  { value: 0, name: "Ống chia còn" },
  { value: 1, name: "Ống chia hết" },
];

export const QUALITY_BOOTLE_LIST = [
  { value: 2, name: "Không đạt" },
  { value: 1, name: "Đạt" },
];

export const STATUS_REQUESTS = {
    success: 200,
    badRequest: 400,
};

export const ROLES  = {
  SUPER_ADMIN: "ROLE_SUPER_ADMIN",
  ADMIN: "ROLE_ADMIN"
}

export const SEARCH_OBJECT_MAX_SIZE = {
  pageIndex: 1,
  pageSize: 99999,
}

export const BLOB_TYPE = {
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
}