import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/rack";

export const searchByPage = (searchObject) => {
  var url = API_PATH + "/search";
  return axios.post(url, searchObject);
};

export const getItemById = (id) => {
  var url = API_PATH + "/" + id;
  return axios.get(url);
};
export const deleteItem = (id) => {
  var url = API_PATH + "/" + id;
  return axios.delete(url);
};
export const addNew = (item) => {
  var url = API_PATH;
  return axios.post(url, item);
};
export const update = (item) => {
  var url = API_PATH + "/" + item.id;
  return axios.put(url, item);
};
export const checkCode = (id, code) => {
  const config = { params: { id: id, code: code } };
  var url = API_PATH + "/check-code";
  return axios.get(url, config);
};

export const getByCabinetId = (id) => {
  var url = API_PATH + "/get-rack-by-cabinet-id/" + id;
  return axios.get(url, id);
};
