import React, { useEffect, useState } from "react";
import PaperComponent from "app/views/Component/Paper/PaperCompnent";
import { useTranslation } from "react-i18next";
import { Dialog, Button, DialogActions, DialogTitle, DialogContent, Icon, IconButton, Radio } from "@material-ui/core";
import MaterialTable, { MTableToolbar } from "material-table";
import { STATUS_REQUESTS } from "app/appContansts";
import { toast } from "react-toastify";
import { getBoxByRackIdAndType } from "../services/SampleServices";

function SelectBoxDialog(props) {
  const { open, handleClose, rackId, handleSaveData, boxData } = props;
  const { t } = useTranslation();

  const [listData, setListData] = useState([]);
  const [selectData, setSelectData] = useState(null);

  useEffect(() => {
    updatePageData();
  }, [rackId]);

  const updatePageData = async () => {
    if (!rackId) return;
    try {
      const { data } = await getBoxByRackIdAndType(rackId, 1);
      if (data.code === STATUS_REQUESTS.success) {
        let dataSelect = (data?.data || []).find((item) => item?.id === boxData?.id);
        setSelectData(dataSelect || null);
        setListData(data?.data || []);
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const handleSave = () => {
    if (!selectData?.id) return toast.warning(t("toast.please_select_box"));
    handleSaveData(selectData);
  };

  const columns = [
    {
      title: t("Action"),
      field: "custom",
      align: "center",
      headerStyle: {
        minWidth: "100px"
      },
      render: (rowData) => (
        <Radio name="selectBox" checked={rowData?.id === selectData?.id} onClick={() => setSelectData(rowData)} />
      )
    },
    {
      title: t("Name"),
      field: "name",
      align: "left"
    },
    {
      title: t("Code"),
      field: "code",
      align: "left"
    },
    {
      title: t("used"),
      field: "custom",
      align: "left",
      render: (rowData) => (
        <span>{`${rowData?.boxPositions?.filter((item) => item?.serumTube)?.length}/${
          rowData?.boxPositions?.length
        }`}</span>
      )
    }
  ];

  return (
    <Dialog open={open} PaperComponent={PaperComponent} maxWidth="md" fullWidth={true}>
      <DialogTitle className="cursor-move">
        <div className="flex flex-middle flex-space-between">
          <span className="mb-20 styleColor">{t("sample.select_box")}</span>
          <IconButton onClick={() => handleClose()}>
            <Icon color="error" title={t("close")}>
              close
            </Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <MaterialTable
          title={t("List")}
          data={listData || []}
          columns={columns}
          parentChildData={(row, rows) => {
            return rows.find((a) => a.id === row.parentId);
          }}
          options={{
            sorting: false,
            selection: false,
            actionsColumnIndex: -1,
            paging: false,
            search: false,
            rowStyle: (_, index) => ({
              backgroundColor: index % 2 === 1 ? "#EEE" : "#FFF"
            }),
            maxBodyHeight: "450px",
            minBodyHeight: "370px",
            headerStyle: {
              backgroundColor: "#358600",
              color: "#fff",
              whiteSpace: "nowrap"
            },
            cellStyle: {
              whiteSpace: "nowrap"
            },
            padding: "dense",
            toolbar: false
          }}
          components={{
            Toolbar: (props) => <MTableToolbar {...props} />
          }}
          localization={{
            body: {
              emptyDataSourceMessage: `${t("general.emptyDataMessageTable")}`
            }
          }}
        />
      </DialogContent>
      <DialogActions spacing={4} className="flex flex-end flex-middle">
        <Button variant="contained" color="secondary" onClick={() => handleClose()}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          {t("Save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectBoxDialog;
