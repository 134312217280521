import moment from "moment";
import { SAMPLE_SEARCH_TYPES, SAMPLE_TYPES_CODES } from "../constant/SampleConst";

export const convertSelectBoxPosition = (data, selectedBoxPositions) => {
  let serumTubes = selectedBoxPositions.map((item) => {
    return {
      ...item,
      sampleSeparationDate: data?.sampleSeparationDate || "",
      freezeDate: data?.freezeDate || "",
      expiryDate: data?.expiryDate || "",
      tubeQuantity: data?.tubeQuantity || "",
      maxVolume: data?.maxVolume || "",
      type: SAMPLE_TYPES_CODES.BLOOD_SERUM,
      boxPosition: item
    };
  });

  return serumTubes;
};

export const formatDate = (date) => {
  return date ? moment(date).format("DD/MM/YYYY") : "";
};

export const convertDataSubmit = (data, type) => {
  let sampleProperties = data?.sampleProperties.map((item) => {
    let { id, ...fillterItem } = item;
    return item?.reagentDto === null && item?.testResult === null ? null : fillterItem;
  });
  return {
    ...data,
    type: SAMPLE_SEARCH_TYPES.find((item) => item?.code === type),
    sampleProperties: sampleProperties.filter((item) => item !== null),
    samplingDate :  (new Date(data?.samplingDate)).getTime()
  };
};

export const convertDataUi = (data) => {
  let splitTubeData =
    Array.isArray(data?.serumTubes) && data?.serumTubes?.length > 0
      ? {
          sampleSeparationDate: data?.serumTubes[0]?.sampleSeparationDate || "",
          freezeDate: data?.serumTubes[0]?.freezeDate || "",
          expiryDate: data?.serumTubes[0]?.expiryDate || "",
          tubeQuantity: data?.serumTubes[0]?.tubeQuantity || "",
          maxVolume: data?.serumTubes[0]?.maxVolume || "",
          tubeSplit: data?.serumTubes?.length
        }
      : {};

  return {
    ...data,
    ...splitTubeData,
    cabinetObj: null,
    rackObj: null
  };
};
