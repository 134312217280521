import axios from "axios";
import ConstantList from "../../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/sample-bag";
const API_PATH_EXPORT_EXCEL = ConstantList.API_ENPOINT + "/api/download-excel";

export const searchByPage = (searchObject) => {
  const url = API_PATH + "/search";
  return axios.post(url, searchObject);
};

export const deleteSample = (ids) => {
  const url = API_PATH + `/sample-bag/list?ids=${ids}`;
  return axios.delete(url);
};

export const getBoxByRackIdAndType = (id, type) => {
  const url = ConstantList.API_ENPOINT + "/api/box/get-by-rack-id-and-type/" + id + "/" + type;
  return axios.get(url);
};

export const uploadImage = (file) => {
  const url = ConstantList.API_ENPOINT + "/api/v2/file/upload/image";
  let formData = new FormData();
  formData.append("image", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };
  return axios.post(url, formData, config);
};

export const addSample = (data) => {
  const url = API_PATH;
  return axios.post(url, data);
};

export const updateSample = (data) => {
  const url = API_PATH + "/" + data?.id;
  return axios.put(url, data);
};

export const exportExcelSample = (body) => {
  const url = API_PATH_EXPORT_EXCEL + "/sample-bag";

  return axios({
    url: url,
    method: "POST",
    responseType: "blob",
    data: body
  });
};

export const addTargetOther = (data) => {
  const url = ConstantList.API_ENPOINT + "/option";
  return axios.post(url, data);
};


export const getTargetOther = (type) => {
  const url = ConstantList.API_ENPOINT + `/option/by-type?type=${type}`;
  return axios.get(url);
};