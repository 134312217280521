import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const Slot = EgretLoadable({
  loader: () => import("./Slot")
});
const ViewComponent = withTranslation()(Slot);

const SlotRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"slot",
    exact: true,
    component: ViewComponent
  }
];

export default SlotRoutes;