// TODO Bỏ comment code, bỏ các phần khi không sử dụng, bỏ console.log
import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';
const SampleBag = EgretLoadable({
  loader: () => import("./SampleBag")
});
const ViewComponent = withTranslation()(SampleBag);

const SampleBagRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"sample-bag",
    exact: true,
    component: ViewComponent
  }
];

export default SampleBagRoutes;