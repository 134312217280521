import axios from "axios";
import localStorageService from "./localStorageService";
import ConstantList from "../appConfig";
import UserService from "../services/UserService";
import MenuService from "../services/MenuService";
import history from "history.js";

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization':'Basic Y29yZV9jbGllbnQ6c2VjcmV0'
  }
}

class JwtAuthService {
  user = {
    userId: "1",
    role: 'ADMIN',
    displayName: "Watson Joyce",
    email: "watsonjoyce@gmail.com",
    photoURL:  ConstantList.ROOT_PATH+"assets/images/avatar.jpg",
    age: 25,
    token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
  }

  async getCurrentUser (){
    const url = ConstantList.API_ENPOINT + "/api/users/getCurrentUser";
    return await axios.get(url);
  };

  async loginWithUserNameAndPassword (username, password) {
    let requestBody ='client_id=core_client&grant_type=password&client_secret=secret';
    requestBody = requestBody + "&username=" + username + "&password=" + password;

    await axios
      .post(ConstantList.API_ENPOINT + "/oauth/token", requestBody, config)
      .then((response) => {
        const dateObj = new Date(Date.now() + response.data.expires_in * 1000);
        localStorageService.setSessionItem("token_expire_time", dateObj);
        this.setSession(response.data.access_token);
      });

    await this.getCurrentUser().then(({ data }) => {
      this.setLoginUser(data);
    });

    await MenuService.getAllMenuItemByRoleList().then(({ data }) => {
      localStorageService.setSessionItem("navigations", data);
    });
  };

  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 1000);
    }).then(data => {
      this.setUser(data);
      this.setSession(data.token);      
      return data;
    });
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.user);
      }, 100);
    }).then(data => {
      this.setSession(data.token);
      this.setUser(data);
      return data;
    });
  };

  

  async logout() {
    if (ConstantList.AUTH_MODE == "Keycloak") {
      UserService.doLogout();
      this.setSession(null);
      this.removeUser();
      history.push(ConstantList.HOME_PAGE);
    } else {
      let url = ConstantList.API_ENPOINT + "/oauth/logout";
      axios.delete(url);
      this.setSession(null);
      this.removeUser();
      history.push(ConstantList.LOGIN_PAGE);
    }
  }

  setSession(token){
    if (token) {
      localStorageService.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorageService.removeItem('jwt_token');
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  async setLoginUser (user) {
    localStorageService.setItem("auth_user", user);
    return user;
  }

  getLoginUser = () => {
    return localStorageService.getItem("auth_user");
  }

  setUser = (user) => {    
    localStorageService.setItem('auth_user', user);

  }
  removeUser = () => {
    localStorageService.removeItem('auth_user');
  }
}

export default new JwtAuthService();
