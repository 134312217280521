import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { setUserData } from "../redux/actions/UserActions";
import jwtAuthService from "../services/jwtAuthService";
import localStorageService from "../services/localStorageService";
import history from "history.js";
import ConstantList from "../appConfig";
class Auth extends Component {
  state = {};
  
  constructor(props) {
    super(props);
    let user = localStorageService.getItem("auth_user");
    let token = localStorageService.getItem("jwt_token");
    let expire_time= localStorageService.getSessionItem("token_expire_time");
    let dateObj = new Date(expire_time);
    if (token) {
      jwtAuthService.setSession(token);
    }
    let isExpired = false;
    if (dateObj) {
      if (dateObj < Date.now()) {
        isExpired = true;
      }
    }
    if (user && !isExpired) {
      this.props.setUserData(user);
    } else {
      history.push(ConstantList.LOGIN_PAGE);
    }
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

const mapStateToProps = state => ({
  setUserData: PropTypes.func.isRequired,
  login: state.login
});

export default connect(
  mapStateToProps,
  { setUserData }
)(Auth);
