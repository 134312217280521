import { Button, Grid, Icon, IconButton, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import LabelRequired from "app/views/Component/LabelRequired";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextValidator } from "react-material-ui-form-validator";
import ConstantList from "../../../appConfig.js";
import { generateId } from "app/utils/common";
import { FIELDS, RESULT_CODES, RESULT_DOWNLINE } from "../constant/SampleConst.js";
import { uploadImage } from "../services/SampleServices.js";
import { STATUS_REQUESTS } from "app/appContansts";
import { toast } from "react-toastify";
import AutocompleteOther from "app/views/Component/AutocompleteOther/AutocompleteOther.jsx";
import ImageViewer from "app/views/Component/ImageReview/ImageReviewer.jsx";

const SampleCharacteristic = (props) => {
    const { t } = useTranslation();
    const {
        isView,
        data,
        handleChangeWithName,
        biologics,
        shouldOpenAddOtherDialog,
        setShouldOpenAddOtherDialog } = props;
    const [formData, setFormData] = useState([]);

    useEffect(() => {
        setFormData(data?.sampleProperties || [])
    }, [data])


    const handleAdd = () => {
        handleChangeWithName(FIELDS.SAMPLE_PROPERTIES, [
            ...formData,
            { id: generateId(formData.map((item) => item?.id)), reagentDto: null, testResult: null }
        ]);
    };

    const handleDelete = (dataDelete) => {
        let newData = formData.filter((item) => item?.id !== dataDelete?.id);
        handleChangeWithName(FIELDS.SAMPLE_PROPERTIES, newData);
    };

    const handleChangeResult = (name, value, id) => {
        let newData = formData.map((item) => {
            return item?.id === id ? { ...item, [name]: value } : item;
        });

        handleChangeWithName(FIELDS.SAMPLE_PROPERTIES, newData);
    };

    const handleUploadImage = async (event, id) => {
        const file = event.target.files[0];
        try {
            const { data } = await uploadImage(file);
            if (data?.code === STATUS_REQUESTS.success) {
                handleChangeResult("fileId", data?.data?.id, id);
                handleChangeResult("filePath", data?.data?.filePath, id);
            }
        } catch (error) {
            toast.error(t("error_message"));
        }
    };

    const handleChangeFinalResult = (finalResultField, value) => {
        const positive = formData.filter((item) => item?.testResult?.code === RESULT_CODES.POSITIVE);
        const negative = formData.filter((item) => item?.testResult?.code === RESULT_CODES.NEGATIVE);
        if (positive?.length >= 3 && value?.code !== RESULT_CODES.POSITIVE) {
            return toast.warning(t("toast.validate_positive"));
        }

        if (negative?.length >= 3 && value?.code !== RESULT_CODES.NEGATIVE) {
            return toast.warning(t("toast.validate_negative"));
        }
        handleChangeWithName(finalResultField, value);
    };

    return (
        <>
            <fieldset className="mb-16">
                <legend className="styleColor">{t("sample.biologicalList")}</legend>
                {!isView &&
                    <div className="flex flex-end">
                        <Button variant="contained" color="primary" onClick={handleAdd} disabled={isView}>
                            {t("sample.addBiological")}
                        </Button>
                    </div>
                }
                {formData.map((item, index) => (
                    <fieldset className="mt-10">
                        <legend>
                            {`${t("sample.sample")} ${index + 1}`}
                            {!isView &&
                                <Tooltip title={t("sample.delete_sample")}>
                                    <IconButton size="small" onClick={() => handleDelete(item)}>
                                        <Icon color="error" fontSize="small">
                                            highlight_off
                                        </Icon>
                                    </IconButton>
                                </Tooltip>
                            }
                        </legend>
                        <Grid container spacing={3}>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <AutocompleteOther
                                    disabled={isView}
                                    label={<LabelRequired label={t("sample.biologic_name")} isRequired />}
                                    handleChange={(name, value) => handleChangeResult("reagentDto", value, item?.id)}
                                    options={biologics}
                                    name="reagentDto"
                                    nameOrther="reagentDto"
                                    value={item?.reagentDto}
                                    getOptionLabel={(option) => option?.name}
                                    validators={["required"]}
                                    errorMessages={[t("Validation.required")]}
                                    handleOther={() => setShouldOpenAddOtherDialog(true)}
                                    isOther={shouldOpenAddOtherDialog}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <Autocomplete
                                    size="small"
                                    options={RESULT_DOWNLINE}
                                    className="flex-end"
                                    clearable
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(_, value) => {
                                        handleChangeResult("testResult", value, item?.id);
                                    }}
                                    value={item?.testResult}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label={<LabelRequired label={t("sample.result")} isRequired />}
                                            value={item?.testResult}
                                            variant="outlined"
                                            validators={["required"]}
                                            errorMessages={[t("Validation.required")]}
                                        />
                                    )}
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item lg={4} md={4} sm={6} xs={12}>
                                <TextValidator
                                    className="w-100"
                                    label={<LabelRequired label={t("sample.C_O_I")} />}
                                    onChange={(e) => handleChangeResult("coi", e.target.value, item?.id)}
                                    type="number"
                                    name="coi"
                                    value={item?.coi}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextValidator
                                    className="w-100"
                                    label={<LabelRequired label={t("sample.note")} />}
                                    onChange={(e) => handleChangeResult("note", e.target.value, item?.id)}
                                    name="note"
                                    multiline
                                    rows={4}
                                    value={item?.note}
                                    variant="outlined"
                                    size="small"
                                    disabled={isView}
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <div className="flex flex-start">
                                    <label htmlFor={"previewImg" + item?.id} className="mr-16">
                                        <div className="btn-div">
                                            {item?.filePath ? t("sample.edit_image_preview") : t("sample.add_image_preview")}
                                        </div>
                                    </label>
                                    <input
                                        type="file"
                                        hidden
                                        id={"previewImg" + item?.id}
                                        onChange={(event) => handleUploadImage(event, item?.id)}
                                        accept="image/jpeg, image/jpg, image/png"
                                    />
                                    <div className="flex-1">
                                        {item?.filePath && (
                                            <ImageViewer image={ConstantList.API_ENPOINT + item?.filePath} />
                                        )}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </fieldset>
                ))}
            </fieldset>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <fieldset>
                        <legend className="styleColor">{t("sample.result_downline")}</legend>
                        <Grid container spacing={3} className="p-12">
                            <Grid item xs={6}>
                                <Autocomplete
                                    size="small"
                                    options={RESULT_DOWNLINE}
                                    className="flex-end"
                                    clearable
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(_, value) => {
                                        handleChangeFinalResult("finalDiagnosticResult", value);
                                    }}
                                    value={data?.finalDiagnosticResult || null}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label={<LabelRequired label={t("sample.result_final")} isRequired />}
                                            value={data?.finalDiagnosticResult || null}
                                            variant="outlined"
                                            validators={["required"]}
                                            errorMessages={[t("Validation.required")]}
                                        />
                                    )}
                                    disabled={isView}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <fieldset>
                        <legend className="styleColor">{t("sample.result_NIHE")}</legend>
                        <Grid container spacing={3} className="p-12">
                            <Grid item xs={6}>
                                <Autocomplete
                                    size="small"
                                    options={RESULT_DOWNLINE}
                                    className="flex-end"
                                    clearable
                                    getOptionLabel={(option) => option?.name}
                                    onChange={(_, value) => {
                                        handleChangeFinalResult("finalResultsNihe", value);
                                    }}
                                    value={data?.finalResultsNihe || null}
                                    renderInput={(params) => (
                                        <TextValidator
                                            {...params}
                                            label={<LabelRequired label={t("sample.result_final")} isRequired />}
                                            value={data?.finalResultsNihe || null}
                                            variant="outlined"
                                            validators={["required"]}
                                            errorMessages={[t("Validation.required")]}
                                        />
                                    )}
                                    disabled={isView}
                                />
                            </Grid>
                        </Grid>
                    </fieldset>
                </Grid>
            </Grid>
        </>
    )
}
export default SampleCharacteristic;