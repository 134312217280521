import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { withTranslation } from 'react-i18next';

const ActivityLog = EgretLoadable({
  loader: () => import("./ActivityLog")
});
const ViewComponent = withTranslation()(ActivityLog);

const ActivityLogRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"dashboard/activity-log",
    exact: true,
    component: ViewComponent
  }
];

export default ActivityLogRoutes;