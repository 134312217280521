import { ValidatorForm } from "react-material-ui-form-validator";

export const isInterger = {
  name: "isInterger",
  func: () => {
    ValidatorForm.addValidationRule("isInterger", (value) => {
      return Number.isInteger(Number(value));
    });
  },
  message: "Giá trị phải là số nguyên"
};

export const isMonth = {
  name: "isMonth",
  func: () => {
    ValidatorForm.addValidationRule("isMonth", (value) => {
      let _value = Number(value);
      return _value > 0 && _value < 12;
    });
  },
  message: "Tháng phải lớn hơn 0 và bé hơn 12"
};

export const notMinusNumber = {
  name: "notMinusNumber",
  func: () => {
    ValidatorForm.addValidationRule("notMinusNumber", (value) => {
      let _value = Number(value);
      return _value > 0;
    });
  },
  message: "Không được là số âm"
};
