import {
    Button,
    Card,
    CircularProgress,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    Icon,
} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
import { EgretProgressBar, ShowDialog, ConfirmationDialog } from "egret";
import React from "react";
import Draggable from "react-draggable";
import { toast } from "react-toastify";
import ConstantList from '../../../appConfig'
toast.configure({
    autoClose: 5000,
    draggable: false,
    limit: 3,
});

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}
class UploadFormPopupCustom extends React.Component {
    state = {
        dragClass: "",
        files: [],
        statusList: [],
        queProgress: 0,
        uploadingAllFiles: false,
        shouldOpenShowStatusDialog: false,
        userList: [],
        showMessage: false,
        shouldOpenConfirmationDialog: false,
        isLoading: false
    };

    handleFileUploadOnSelect = (event) => {
        let files = event.target.files;
        this.fileUpload(files[0]).then((res) => {
            toast.success("File uploaded successfully.");
        });
    };

    //
    handleFileSelect = (event) => {
        let files = event.target.files;
        let list = [];

        for (const iterator of files) {
            list.push({
                file: iterator,
                uploading: false,
                error: false,
                progress: 0,
            });
        }

        this.setState(
            {
                files: [...list],
            },
            function () {
                document.getElementById("upload-single-file").value = null;
            }
        );
    };

    handleDragOver = (event) => {
        event.preventDefault();
        this.setState({ dragClass: "drag-shadow" });
    };

    handleDrop = (event) => {
        event.preventDefault();
        event.persist();
        let { t } = this.props;
        let files = event.dataTransfer.files;
        let list = [];
        let acceptType = this.props.acceptType.split(";");
        for (const iterator of files) {
            if (
                iterator.type.split("/").length <= 0 ||
                acceptType.indexOf(iterator.type.split("/")[1]) < 0
            ) {
                console.log(acceptType);
                console.log(iterator.type.split("/"));
                toast.error(t("general.type_not_accepted"));
                break;
            }
            list.push({
                file: iterator,
                uploading: false,
                error: false,
                progress: 0,
            });
        }

        this.setState({
            dragClass: "",
            files: [...list],
        });

        return false;
    };

    handleDragStart = (event) => {
        this.setState({ dragClass: "drag-shadow" });
    };

    handleDialogClose = () => {
        this.setState({
            shouldOpenShowStatusDialog: false,
        });
        this.props.handleClose();
    };

    handleSingleRemove = (index) => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({
            files: [...files],
            description: ''
        });
    };

    handleAllRemove = () => {
        this.setState({ files: [] });
    };

    fileUpload(file) {
        const url = this.props.uploadUrl;
        let formData = new FormData();
        formData.append("uploadfiles", file);
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "Accept": "*/*",                
            },
        };
        return axios.post(url, formData, config);
    }

    multipleFilesUpload(files) {
        const url = this.props.uploadUrl;
        let formData = new FormData();
        for (let file of files) {
            formData.append("uploadfiles", file); //Lưu ý tên 'uploadfile' phải trùng với tham số bên Server side
        }
        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        return axios.post(url, formData, config);
    }

    downloadFileAfterError = (listError) => {
        let urlDownLoadError = ConstantList.API_ENPOINT + '/api/download-excel/file/error/sample-bag';
        return axios.post(urlDownLoadError, listError, { responseType: 'blob' })
    }

    uploadSingleFile = () => {
        this.setState({ isLoading: true })
        let { t } = this.props;
        this.fileUpload(this.state.files[0].file)
            .then(({ data }) => {
                if (data?.code === 200) {
                    toast.success(t("general.upload_excel_success"));
                    this.setState({ isLoading: false })
                } else if (data?.code === 400) {
                    this.downloadFileAfterError(data?.data)
                        .then(({data}) => {
                            toast.error(t("general.fileImportError"))
                            const blobData = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            const url = window.URL.createObjectURL(blobData);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", 'ErrorImportSampleBag.xlsx');
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ isLoading: false })
                        })
                        .catch(() => this.setState({ isLoading: false }))
                } else {
                    toast.error(t("general.errorSystem"))
                    this.setState({ isLoading: false })
                }
            });
    };

    uploadAllFile = () => {
        let allFiles = [];
        let { t } = this.props;
        // debugger;
        this.state.files.map((item) => {
            allFiles.push({
                ...item,
                uploading: true,
                error: false,
            });

            return item;
        });
        const files = allFiles.map((file) => file.file);
        this.setState({ uploadingAllFiles: false, shouldOpenConfirmationDialog: false },
            function () {
                if (files.length === 0) {
                    toast.error(t("general.not_select_file"));
                } else {
                    this.multipleFilesUpload(files).then((res) => {
                        const listData=res.data.data
                        let message = res.data.message;
                        this.setState({ description: message })
                        if (listData.every(data=>data.code===200)) {
                            toast.success("Nhập dữ liệu thành công");
                            this.props.handleClose();
                        } else{
                           listData.map((data,index)=>{
                            if(data.code !==200) {
                                    toast.error(
                                      `File ${data.fileName}:  ${data.message}`
                                    );
                            }
                           })
                        }
                    }).catch((err) => {
                        if (err.response?.data) {
                            toast.error(err.response.data.errorMessage);
                        } else toast.error(t("general.upload_excel_error"));
                        this.props.handleClose();
                        this.setState({
                            uploadingAllFiles: false,
                        });
                    });
                }
            }
        );

        this.setState({
            files: [...allFiles],
            queProgress: 35,
        });
    };

    handleSingleCancel = (index) => {
        let allFiles = [...this.state.files];
        let file = this.state.files[index];

        allFiles[index] = { ...file, uploading: false, error: true };

        this.setState({
            files: [...allFiles],
        });
    };

    handleUploadConfirm = (id) => {
        this.setState({
            shouldOpenConfirmationDialog: true,
        });
    };

    handleCancelAll = () => {
        let allFiles = [];

        this.state.files.map((item) => {
            allFiles.push({
                ...item,
                uploading: false,
                error: true,
            });

            return item;
        });

        this.setState({
            files: [...allFiles],
            queProgress: 0,
        });
    };

    render() {
        const { t, i18n, handleClose, handleSelect, selectedItem, open, confirmMessage } =
            this.props;
        let { dragClass, files, queProgress, shouldOpenShowStatusDialog, shouldOpenConfirmationDialog, showMessage } =
            this.state;
        let isEmpty = files.length === 0;

        return (
            <Dialog
                onClose={handleClose}
                open={open}
                PaperComponent={PaperComponent}
                maxWidth={"md"}                                                           
                fullWidth
            >
                <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
                    <span className="mb-20">{t("general.upload")}</span>
                </DialogTitle>
                {
                    this.state.isLoading ? (
                        <div
                          style={{
                            zIndex: "10000000",
                            position: "fixed",
                            height: "100%",
                            width: "100%",
                            top: 0,
                            left: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(0, 0, 0, 0.54)",
                          }}
                        >
                          <CircularProgress size={60} style={{}} />
                        </div>
                      ) : null
                }
                <DialogContent>
                    <div className="upload-form m-sm-30">
                        <div className="flex flex-wrap mb-20">
                            <label htmlFor="upload-single-file">
                                <Button
                                    size="small"
                                    className="capitalize"
                                    component="span"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.uploadingAllFiles}
                                >
                                    <div className="flex flex-middle">
                                        <Icon className="pr-8">cloud_upload</Icon>
                                        <span>{t("general.select_file")}</span>
                                    </div>
                                </Button>
                            </label>
                            <input
                                className="display-none"
                                onChange={this.handleFileSelect}
                                id="upload-single-file"
                                type="file"
                                multiple
                            />
                        </div>
                        <div
                            className={`${dragClass} upload-drop-box flex flex-center flex-middle`}
                            onDragEnter={this.handleDragStart}
                            onDragOver={this.handleDragOver}
                            onDrop={this.handleDrop}
                        >
                            {isEmpty ? (
                                <span>Thả file vào đây</span>
                            ) : (
                                <h5 className="m-0">
                                    {files.length} file{files.length > 1 ? "s" : ""} được chọn...
                                </h5>
                            )}
                        </div>
                        <Card className="mb-24" elevation={2}>
                            <div className="p-16">
                                <Grid
                                    container
                                    spacing={2}
                                    justify="center"
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Grid item lg={4} md={4}>
                                        {t("general.file_name")}
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                        {t("general.size")}
                                    </Grid>
                                    <Grid item lg={4} md={4}>
                                        {t("general.action")}
                                    </Grid>
                                </Grid>
                            </div>
                            <Divider></Divider>

                            {isEmpty && (
                                <p className="px-16 center">{t("general.empty_file")}</p>
                            )}
                            {files.map((item, index) => {
                                let { file, uploading, error, progress } = item;
                                return (
                                    <div className="px-16 py-16" key={file.name}>
                                        <Grid
                                            container
                                            spacing={2}
                                            justify="center"
                                            alignItems="center"
                                            direction="row"
                                        >
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                {file.name}
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={12} xs={12}>
                                                {(file.size / 1024 / 1024).toFixed(1)} MB
                                            </Grid>
                                            <Grid item lg={2} md={2} sm={12} xs={12}>
                                                <EgretProgressBar value={progress}></EgretProgressBar>
                                            </Grid>
                                            <Grid item lg={1} md={1} sm={12} xs={12}>
                                                {error && <Icon color="error">error</Icon>}
                                                {/* {uploading && <Icon className="text-green">done</Icon>} */}
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                                <div className="flex">
                                                    <Button
                                                        variant="contained"
                                                        className="bg-error"
                                                        disabled={this.state.uploadingAllFiles}
                                                        onClick={() => this.handleSingleRemove(index)}
                                                    >
                                                        {t("general.remove")}
                                                    </Button>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </Card>

                    </div>
                    {shouldOpenShowStatusDialog && (
                        <ShowDialog
                            title="Error"
                            open={shouldOpenShowStatusDialog}
                            onConfirmDialogClose={this.handleDialogClose}
                            text={this.state.description}
                            cancel={"OK"}
                        />
                    )}
                    {shouldOpenConfirmationDialog && (
                        <ConfirmationDialog
                            title={t("confirm")}
                            open={shouldOpenConfirmationDialog}
                            onConfirmDialogClose={this.handleDialogClose}
                            onYesClick={this.uploadAllFile}
                            text={confirmMessage}
                            Yes={t("general.Yes")}
                            No={t("general.No")}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        className="mb-16 mr-36 align-bottom"
                        variant="contained"
                        color="secondary"
                        disabled={this.state.uploadingAllFiles}
                        onClick={() => handleClose()}
                    >
                        {t("general.close")}
                    </Button>
                    <Button
                        className="mb-16 mr-36 align-bottom"
                        variant="contained"
                        color="primary"
                        disabled={this.state.uploadingAllFiles}
                        onClick={
                            this.state.files.length === 1
                                ? this.uploadSingleFile
                                : this.handleUploadConfirm
                        }
                    >
                        {t("general.upload")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
export default UploadFormPopupCustom;
