import React from "react";
import { Redirect } from "react-router-dom";
import homeRoutes from "./views/home/HomeRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import administrativeUnitRoutes from "./views/AdministrativeUnit/AdministrativeUnitRoutes";
import fiscalYearRoutes from "./views/FiscalYear/FiscalYearRoutes";
import otherRoutes from "./views/others/OtherRoutes";
import UserRoutes from "./views/User/UserRoutes";
import departmentRoutes from "./views/Department/DepartmentRoutes";
import roleRoutes from "./views/Role/RoleRoutes";
import ConstantList from "./appConfig";
import MenuRoutes from "./views/Menus/MenuRoutes";
import ActivityLogRoutes from "./views/ActivityLog/ActivityLogRoutes"
import pageLayoutRoutes from "./views/page-layouts/PageLayoutRoutees";
import SampleBagRoutes from "./views/SampleBag/SampleBagRoutes";
import reagentRoutes from "./views/Reagent/ReagentRoutes";
import CabinetRoutes from "./views/Cabinet/CabinetRoutes";
import LabTestResultRoutes from "./views/LabTestResult/LabTestResultRoutes";
import BoxRoutes from "./views/Box/BoxRoutes";
import RackRoutes from "./views/Rack/RackRoutes";
import ReportRoutes from "./views/Report/ReportRoutes";
import SlotRoutes from "./views/Slot/SlotRoutes";
import SerumTubeRoutes from "./views/SerumTube/SerumTubeRoutes";
import ExportInvoiceRouters from "./views/ExportInvoice/ExportInvoiceRouters";
import CommingSoonRoutes from "./views/CommingSoon/CommingSoonRoutes";
import HealthOrg from "./views/HealthOrg/HealthOrgRoutes";
import TestMachine from "./views/TestMachine/TestMachineRouters";
import ManageImageRoutes from "./views/ManageImage/ManageImageRoutes";
import SampleRoutes from "./views/Sample/SampleRoutes.jsx";
const redirectRoute = [
  {
    path: ConstantList.ROOT_PATH,
    exact: true,
    component: () => <Redirect to={ConstantList.HOME_PAGE} />//Luôn trỏ về HomePage được khai báo trong appConfig
  }
];

const errorRoute = [
  {
    component: () => <Redirect to={ConstantList.ROOT_PATH + "session/404"} />
  }
];

const routes = [
  ...homeRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...LabTestResultRoutes,
  ...ReportRoutes,
  ...administrativeUnitRoutes,
  ...ActivityLogRoutes,
  ...fiscalYearRoutes,
  ...departmentRoutes,
  ...pageLayoutRoutes,
  ...UserRoutes,
  ...roleRoutes,
  ...MenuRoutes,
  ...ManageImageRoutes,
  // ...SampleBagRoutes,
  ...reagentRoutes,
  ...CabinetRoutes,
  ...BoxRoutes,
  ...SlotRoutes,
  ...SerumTubeRoutes,
  ...SampleRoutes,
  ...RackRoutes,
  ...ExportInvoiceRouters,
  ...CommingSoonRoutes,
  ...HealthOrg,
  ...TestMachine,
  ...errorRoute

];

export default routes;
