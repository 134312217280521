import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";

const ManageImage = EgretLoadable({
  loader: () => import("./ManageImage")
});

const ManageImageRoutes = [
  {
    path: ConstantList.ROOT_PATH + "manager/image",
    exact: true,
    component: ManageImage
  }
];

export default ManageImageRoutes;
