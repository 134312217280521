import { generateId } from "app/utils/common";

export const SAMPLE_SEARCH_TABS = {
  DIAGNOSTIC_SAMPLE: 0,
  METHOD_EVALUATION_SAMPLE: 1,
  PRODUCTION_SAMPLE: 2,
  RESEARCH_SAMPLE: 3
};

export const SAMPLE_SEARCH_CODES = {
  DIAGNOSTIC_SAMPLE: 1,
  METHOD_EVALUATION_SAMPLE: 2,
  PRODUCTION_SAMPLE: 3,
  RESEARCH_SAMPLE: 4
};

export const SAMPLE_SEARCH_TYPES = [
  {
    code: SAMPLE_SEARCH_CODES.DIAGNOSTIC_SAMPLE,
    name: "CĐ"
  },
  {
    code: SAMPLE_SEARCH_CODES.METHOD_EVALUATION_SAMPLE,
    name: "ĐGPC"
  },
  {
    code: SAMPLE_SEARCH_CODES.PRODUCTION_SAMPLE,
    name: "SX"
  },
  {
    code: SAMPLE_SEARCH_CODES.RESEARCH_SAMPLE,
    name: "NC"
  }
];

export const SAMPLE_TYPES_CODES = {
  BLOOD_SERUM: 1,
  PLASMA: 2,
  BUFFY_COAT: 3
}

export const SAMPLE_TYPES = [
  {
    code: SAMPLE_TYPES_CODES.BLOOD_SERUM,
    name: "Huyết thanh"
  },
  {
    code: SAMPLE_TYPES_CODES.PLASMA,
    name: "Huyết tương"
  },
  {
    code: SAMPLE_TYPES_CODES.BUFFY_COAT,
    name: "Buffy coat"
  }
];

export const GENDERS = [
  {
    code: 1,
    name: "Nam"
  },
  {
    code: 1,
    name: "Nữ"
  }
];

export const ANTECEDENT_ARV_CODES = {
  YES: 1,
  NO: 2
};

export const ANTECEDENT_ARV = [
  {
    code: ANTECEDENT_ARV_CODES.YES,
    name: "Có"
  },
  {
    code: ANTECEDENT_ARV_CODES.NO,
    name: "Không"
  }
];

export const RESULT_CODES = {
  POSITIVE: 1,
  NEGATIVE: 2,
  UNKNOWN: 3
};

export const RESULT_DOWNLINE = [
  {
    code: 1,
    name: "Dương tính"
  },
  {
    code: 2,
    name: "Âm tính"
  },
  {
    code: 3,
    name: "Không xác định"
  }
];

export const TYPE_DOWLINE = {
  code: 1,
  name: "Chuẩn đoán tại tuyến dưới"
};

export const TYPE_NIHE = {
  code: 2,
  name: "Nihe"
};

export const FIELDS = {
  SAMPLE_PROPERTIES: "sampleProperties",
  ARV_HISTORY_OBJ: "arvHistoryObj",
  SAMPLE_SEPARATION_DATE: "sampleSeparationDate",
  CABINET_OBJ: "cabinetObj",
  RACK_OBJ: "rackObj",
  BOX_EDIT: "boxEdit"
};

export const INIT_SAMPLE = {
  sampleProperties: [
    {
      type: TYPE_DOWLINE,
      id: generateId(),
      reagentDto: null,
      testResult: null
    },
    {
      type: TYPE_NIHE,
      id: generateId(),
      reagentDto: null,
      testResult: null
    }
  ],
  rackObj: null,
  cabinetObj: null,
  box: null,
  sampleSeparationDate: "",
  freezeDate: "",
  expiryDate: "",
  tubeQuantity: "",
  maxVolume: null,
  serumTubeSet: []
};

export const BOX_TYPES = [
  {
    code: -1,
    name: "Box âm"
  },
  {
    code: 1,
    name: "Box dương"
  }
];

export const SAMPLE_DIALOG_TABS = {
  INFOMATION: 0,
  PROPERTIES: 1,
  SPLIT_TUBE: 2
}

export const OBJECT_TYPE = {
  TARGET: 1
}