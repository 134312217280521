/*TODO format code, xóa consolog, comment thừa, xử lý bestpractise về REACT JS và java script*/
import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/administrative-unit";

export const searchByDto = (searchObject) => {
  const url = API_PATH + "/search";
  return axios.post(url, searchObject);
};

export const getAllAdministrativeUnits = () => {
  return axios.get(ConstantList.API_ENPOINT + "/api/administrativeunit/1/10");
};

export const getByPage = (searchDto) => {
  const url = API_PATH + "/search";
  return axios.post(url, searchDto);
};

export const checkCode = (id, code) => {
  const config = { params: { id: id, code: code } };
  const url = API_PATH + "/check-code";
  return axios.get(url, config);
};

export const getByPage1 = (searchDto) => {
  const url = ConstantList.API_ENPOINT + "/api/administrativeunit";
  return axios.get(url, searchDto);
};

export const getUserById = (id) => {
  const url = ConstantList.API_ENPOINT + "/api/administrativeunit/" + id;
  return axios.get(url);
};

export const deleteAdministrativeUnit = (id) => {
  return axios.delete(
    ConstantList.API_ENPOINT + "/api/administrativeunit/" + id
  );
};

export const addNewAdministrativeUnit = (adminUnit) => {
  return axios.post(
    ConstantList.API_ENPOINT + "/api/administrativeunit",
    adminUnit
  );
};

export const updateAdministrativeUnit = (adminUnit) => {
  return axios.post(
    ConstantList.API_ENPOINT + "/api/administrativeunit",
    adminUnit
  );
};
