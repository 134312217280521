import { Grid } from "@material-ui/core";
import LabelRequired from "app/views/Component/LabelRequired";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AutocompleteOther from "app/views/Component/AutocompleteOther/AutocompleteOther";
import { ANTECEDENT_ARV, ANTECEDENT_ARV_CODES, RESULT_DOWNLINE, SAMPLE_TYPES, GENDERS } from "../constant/SampleConst";
import { isInterger, isMonth, notMinusNumber } from "app/utils/CustomValidate";
import { toast } from "react-toastify";
import { getByPage } from "app/views/AdministrativeUnit/AdministrativeUnitService";
import { SEARCH_OBJECT_MAX_SIZE, STATUS_REQUESTS } from "app/appContansts";
import { ValidatedDatePicker, ValidatedDateTimePicker } from "app/views/Component/CustomValidateComponent";
import { customFormatDate } from "utils";

function SampleInfomation(props) {
  const { data,
    t,
    handleChange,
    handleChangeWithName,
    isView,
    setShouldOpenAddTargetOther,
    dataOtherTargets,
    shouldOpenAddTargetOther
  } = props;
  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
    ValidatorForm.addValidationRule("isMatchResult", (value) => {
      if (value?.code && data?.diagnosticResult?.code) {
        return value?.code === data?.diagnosticResult?.code;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule("isMatchResult");
    };
  }, [data?.diagnosticResult?.code]);

  const getProvinces = async () => {
    try {
      const { data } = await getByPage(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === STATUS_REQUESTS.success) {
        let provinceConvert = (data?.data?.content || []).map((item) => item?.name).sort();
        setProvinces(provinceConvert);
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  useEffect(() => {
    isInterger.func();
    isMonth.func();
    notMinusNumber.func();

    getProvinces();

    return () => {
      ValidatorForm.removeValidationRule(isInterger.name);
      ValidatorForm.removeValidationRule(isMonth.name);
      ValidatorForm.removeValidationRule(notMinusNumber.name);
      ValidatorForm.removeValidationRule("isMatchResult");
    };
  }, []);
  console.log(dataOtherTargets)
  return (
    <Grid className="mb-8" container spacing={3}>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.bank_code")} />}
          onChange={handleChange}
          type="text"
          name="code"
          value={data?.code}
          variant="outlined"
          size="small"
          disabled
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.code")} isRequired />}
          onChange={handleChange}
          type="text"
          name="sampleCode"
          value={data?.sampleCode}
          validators={["required"]}
          errorMessages={[t("Validation.required")]}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Autocomplete
          size="small"
          options={provinces}
          className="flex-end"
          clearable
          onChange={(_, value) => {
            handleChangeWithName("provinceName", value);
          }}
          value={data?.provinceName}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label={<LabelRequired label={t("sample.province")} isRequired />}
              value={data?.provinceName}
              variant="outlined"
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
            />
          )}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.unit_send")} />}
          onChange={handleChange}
          type="text"
          name="organizationText"
          value={data?.organizationText}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.unit_code_name")} />}
          onChange={handleChange}
          type="text"
          name="organizationCode"
          value={data?.organizationCode}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ValidatedDateTimePicker
          size="small"
          className="w-100"
          label={<LabelRequired label={t("sample.time_date_sampling")} isRequired />}
          inputVariant="outlined"
          type="text"
          autoOk={true}
          name="samplingDate"
          format="dd/MM/yyyy HH:mm:ss"
          value={data?.samplingDate || null}
          onChange={(date) => handleChangeWithName("samplingDate", date)}
          fullWidth
          invalidDateMessage={t("general.errorMessages_invalid_date")}
          validators={["required"]}
          errorMessages={t("Validation.required")}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.volume")} />}
          onChange={handleChange}
          type="number"
          name="volume"
          value={data?.volume}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <AutocompleteOther
          label={<LabelRequired label={t("sample.type")} isRequired />}
          validators={["required"]}
          errorMessages={[t("Validation.required")]}
          handleChange={handleChangeWithName}
          options={SAMPLE_TYPES}
          name="sampleType"
          nameOrther="sampleTypeOther"
          value={data?.sampleType}
          valueOrther={data?.sampleTypeOther}
          getOptionLabel={(option) => option?.name}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <AutocompleteOther
          label={<LabelRequired label={t("sample.object")} isRequired />}
          validators={["required"]}
          errorMessages={[t("Validation.required")]}
          handleChange={(name, value) => handleChangeWithName(name, value?.name)}
          options={dataOtherTargets}
          name="target"
          nameOrther="target"
          handleConvertValue={(value) => dataOtherTargets.find(item => item?.name === value)}
          value={data?.target}
          getOptionLabel={(option) => option?.name}
          disabled={isView}
          isOther={shouldOpenAddTargetOther}
          handleOther={() => setShouldOpenAddTargetOther(true)}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ValidatedDatePicker
          size="small"
          className="w-100"
          label={<LabelRequired label={t("sample.birth_date")} />}
          inputVariant="outlined"
          type="text"
          autoOk={true}
          name="customerDateOfBirth"
          format="dd/MM/yyyy"
          value={data?.customerDateOfBirth || null}
          onChange={(date) => handleChangeWithName("customerDateOfBirth", customFormatDate(date, "YYYY-MM-DD"))}
          fullWidth
          invalidDateMessage={t("general.errorMessages_invalid_date")}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Autocomplete
          size="small"
          options={GENDERS}
          className="flex-end"
          clearable
          getOptionLabel={(option) => option?.name}
          onChange={(_, value) => {
            handleChangeWithName("gender", value);
          }}
          value={data?.gender}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label={<LabelRequired label={t("sample.gender")} />}
              value={data?.gender}
              variant="outlined"
            />
          )}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.cccd_number")} />}
          onChange={handleChange}
          type="text"
          name="idNumber"
          value={data?.idNumber}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={8} md={8} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.address")} />}
          onChange={handleChange}
          type="text"
          name="address"
          value={data?.address}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Autocomplete
          size="small"
          options={ANTECEDENT_ARV}
          className="flex-end"
          clearable
          getOptionLabel={(option) => option?.name}
          onChange={(_, value) => {
            handleChangeWithName("arvHistory", value);
          }}
          value={data?.arvHistory}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label={<LabelRequired label={t("sample.antecedent_ARV")} />}
              value={data?.arvHistory}
              variant="outlined"
            />
          )}
          disabled={isView}
        />
      </Grid>
      {data?.arvHistory?.code === ANTECEDENT_ARV_CODES.YES && (
        <>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.year_amount")} isRequired />}
              onChange={handleChange}
              type="number"
              name="numberOfYears"
              value={data?.numberOfYears}
              variant="outlined"
              size="small"
              validators={["required", notMinusNumber.name]}
              errorMessages={[t("Validation.required"), notMinusNumber.message]}
              disabled={isView}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.month_amount")} isRequired />}
              onChange={handleChange}
              type="number"
              name="numberOfMonths"
              value={data?.numberOfMonths}
              variant="outlined"
              size="small"
              validators={["required", notMinusNumber.name, isInterger.name, isMonth.name]}
              errorMessages={[t("Validation.required"), notMinusNumber.message, isInterger.message, isMonth.message]}
              disabled={isView}
            />
          </Grid>
        </>
      )}
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Autocomplete
          size="small"
          options={RESULT_DOWNLINE}
          className="flex-end"
          clearable
          getOptionLabel={(option) => option?.name}
          onChange={(_, value) => {
            handleChangeWithName("diagnosticResult", value);
          }}
          value={data?.diagnosticResult}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label={<LabelRequired label={t("sample.result_downline")} />}
              value={data?.diagnosticResult}
              variant="outlined"
            />
          )}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ValidatedDatePicker
          size="small"
          className="w-100"
          label={<LabelRequired label={t("sample.date_diagnose")} />}
          inputVariant="outlined"
          type="text"
          autoOk={true}
          name="diagnosisDate"
          format="dd/MM/yyyy"
          value={data?.diagnosisDate || null}
          onChange={(date) => handleChangeWithName("diagnosisDate", customFormatDate(date, "YYYY-MM-DD"))}
          fullWidth
          invalidDateMessage={t("general.errorMessages_invalid_date")}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextValidator
          className="w-100"
          label={<LabelRequired label={t("sample.tube_amount")} />}
          onChange={handleChange}
          type="number"
          name="tubeNumber"
          value={data?.tubeNumber}
          variant="outlined"
          size="small"
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ValidatedDatePicker
          size="small"
          className="w-100"
          label={<LabelRequired label={t("sample.date_receive")} isRequired />}
          inputVariant="outlined"
          type="text"
          autoOk={true}
          name="dateOfReceive"
          format="dd/MM/yyyy"
          value={data?.dateOfReceive || null}
          onChange={(date) => handleChangeWithName("dateOfReceive", customFormatDate(date, "YYYY-MM-DD"))}
          fullWidth
          invalidDateMessage={t("general.errorMessages_invalid_date")}
          validators={["required"]}
          errorMessages={[t("Validation.required")]}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <ValidatedDatePicker
          size="small"
          className="w-100"
          label={<LabelRequired label={t("sample.date_perform")} isRequired />}
          inputVariant="outlined"
          type="text"
          autoOk={true}
          name="dateOfTest"
          format="dd/MM/yyyy"
          value={data?.dateOfTest || null}
          onChange={(date) => handleChangeWithName("dateOfTest", customFormatDate(date, "YYYY-MM-DD"))}
          fullWidth
          invalidDateMessage={t("general.errorMessages_invalid_date")}
          validators={["required"]}
          errorMessages={[t("Validation.required")]}
          disabled={isView}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Autocomplete
          size="small"
          options={RESULT_DOWNLINE}
          className="flex-end"
          clearable
          getOptionLabel={(option) => option?.name}
          onChange={(_, value) => {
            handleChangeWithName("resultsNihe", value);
          }}
          value={data?.resultsNihe}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label={<LabelRequired label={t("sample.result_test")} />}
              value={data?.resultsNihe}
              variant="outlined"
              validators={["isMatchResult"]}
              errorMessages={[t("Validation.diagnostic_result_not_match")]}
            />
          )}
          disabled={isView}
        />
      </Grid>
    </Grid>
  );
}

export default SampleInfomation;
