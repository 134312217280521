import React, { useEffect, useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Button,
  Input,
  InputAdornment,
  Grid,
  TablePagination,
  IconButton,
  Icon
} from "@material-ui/core";
import { Breadcrumb, ConfirmationDialog } from "egret";
import MaterialTable, { MTableToolbar } from "material-table";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { FilterList, Search } from "@material-ui/icons";
import { toast } from "react-toastify";
import { deleteSample, exportExcelSample, searchByPage } from "./services/SampleServices";
import { BLOB_TYPE, ROLES, STATUS_REQUESTS } from "app/appContansts";
import SampleDialog from "./components/SampleDialog";
import "../../../styles/views/_style.scss";
import { SAMPLE_SEARCH_TABS, SAMPLE_SEARCH_CODES } from "./constant/SampleConst";
import { formatDate } from "./utils/SampleUtils";
import { saveAs } from "file-saver";
import UploadFormPopupCustom from "../Component/UploadFormPopup/UploadFormPopupCustom";
import ConstantList from "../../appConfig";
import jwtAuthService from "app/services/jwtAuthService";
import { checkRoleExist } from "app/utils/common";
import CircularIndeterminate from "../utilities/LoadingCircular";

function Sample({ type }) {
  const { t } = useTranslation();
  const [totalElements, setTotalElements] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keyword, setKeyword] = useState("");
  const [listData, setListData] = useState([]);
  const [itemEdit, setItemEdit] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isOpenComfirmDialog, setIsOpenComfirmDialog] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchObject, setSearchObject] = useState({});
  const [isOpenImportExcel, setIsOpenImportExcel] = useState(false);
  const [loginUser, setLoginUser] = useState({});
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoginUser(jwtAuthService.getLoginUser() || {});
  }, []);

  useEffect(() => {
    updatePageData();
  }, [page, rowsPerPage]);

  const updatePageData = async (searchData = {}) => {
    try {
      setLoading(true);
      let searchObj = {
        keyword: keyword.trim(),
        pageIndex: page + 1,
        pageSize: rowsPerPage,
        type: type,
        ...searchData
      };
      setSearchObject(searchObj);
      const { data } = await searchByPage(searchObj);
      if (data.code === STATUS_REQUESTS.success) {
        setListData(data?.data?.content || []);
        setTotalElements(data?.data.totalElements || 0);
      }
    } catch (error) {
      toast.error(t("error_message"));
    } finally {
      setLoading(false);
    }
  };

  const hadleBtnAddNewClick = () => {
    setIsView(false);
    setIsEdit(false);
    setIsOpenDialog(true);
    setItemEdit({});
  };

  const hanleBtnEditCick = (rowData) => {
    setIsView(false);
    setIsEdit(true);
    setItemEdit(rowData);
    setIsOpenDialog(true);
  };

  const handleView = (rowData) => {
    setIsView(true);
    setIsEdit(false);
    setItemEdit(rowData);
    setIsOpenDialog(true);
  };

  const hanleBtnDeleteCick = () => {
    if (!selectedData?.length > 0) return toast.warning(t("toast.please_select_data_delete"));
    setIsOpenComfirmDialog(true);
  };

  const handleDelete = async () => {
    try {
      let ids = selectedData.map((item) => item.id).toString();
      const { data } = await deleteSample(ids);
      if (data?.code === STATUS_REQUESTS.success) {
        toast.success(t("toast.delete_success"));
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
    updatePageData();
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportExcelSample(searchObject);
      toast.info(t("general.successExport"));
      let blob = new Blob([res?.data], {
        type: BLOB_TYPE.XLSX
      });
      saveAs(blob, "Ngân_hàng_mẫu.xlsx");
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const handleImportExcel = () => {
    setIsOpenImportExcel(true);
  };

  const columns = [
    {
      title: t("Action"),
      field: "custom",
      align: "center",
      headerStyle: {
        minWidth: "100px"
      },
      render: (rowData) => (
        <>
          {checkRoleExist(loginUser?.roles, [ROLES.SUPER_ADMIN, ROLES.ADMIN]) && (
            <IconButton size="small" onClick={() => hanleBtnEditCick(rowData)}>
              <Icon fontSize="small" color="primary">
                edit
              </Icon>
            </IconButton>
          )}
          <IconButton size="small" onClick={() => handleView(rowData)}>
            <Icon fontSize="small" color="secondary">
              visibility
            </Icon>
          </IconButton>
        </>
      )
    },
    {
      title: t("sample.bank_code"),
      field: "code",
      align: "left"
    },
    {
      title: t("sample.code"),
      field: "sampleCode",
      align: "left"
    },
    {
      title: t("sample.type"),
      field: "sampleType",
      align: "left",
      render: (rowData) => <span>{rowData?.sampleType?.name}</span>
    },
    {
      title: t("sample.volume"),
      field: "volume",
      align: "left"
    },
    {
      title: t("sample.position"),
      field: "boxPositionCode",
      align: "left"
    },
    {
      title: t("sample.storage_date"),
      field: "dateOfReceive",
      align: "left",
      render: (rowData) => <span>{formatDate(rowData?.dateOfReceive)}</span>
    }
  ];

  return (
    <div className="m-sm-30">
      {loading && <CircularIndeterminate />}
      <Helmet>
        <title>
          {t("sampleBag.title")} | {t("web_site")}
        </title>
      </Helmet>
      <div className="mb-sm-30">
        <Breadcrumb routeSegments={[{ name: t("category"), path: "/" }, { name: t("sampleBag.title") }]} />
      </div>
      <Grid container spacing={2} className="mt-16">
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Button
            className="mb-16 mr-16 align-bottom"
            variant="contained"
            color="primary"
            onClick={hadleBtnAddNewClick}
          >
            {t("Add")}
          </Button>
          <Button className="mb-16 mr-16 align-bottom" variant="contained" color="primary" onClick={handleImportExcel}>
            {t("general.importExcel")}
          </Button>
          <Button className="mb-16 mr-16 align-bottom" variant="contained" color="primary" onClick={handleExportExcel}>
            {t("general.exportExcel")}
          </Button>
          {checkRoleExist(loginUser?.roles, [ROLES.SUPER_ADMIN]) && (
            <Button
              className="mb-16 mr-16 align-bottom"
              variant="contained"
              color="primary"
              onClick={hanleBtnDeleteCick}
            >
              {t("Delete")}
            </Button>
          )}
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Input
            label={t("EnterSearch")}
            type="text"
            name="keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            className="w-100 mb-16 mr-10 stylePlaceholder"
            id="search_box"
            placeholder={t("general.enterSearch")}
            startAdornment={
              <InputAdornment>
                <Search
                  onClick={() => {
                    updatePageData({ pageIndex: 0 });
                    setPage(0);
                  }}
                  className="icon search-icon"
                />
                <FilterList className="icon filter-icon" onClick={() => setIsFilter(!isFilter)} />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
      <MaterialTable
        title={t("List")}
        data={listData || []}
        columns={columns}
        parentChildData={(row, rows) => {
          return rows.find((a) => a.id === row.parentId);
        }}
        options={{
          sorting: false,
          selection: true,
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          rowStyle: (_, index) => ({
            backgroundColor: index % 2 === 1 ? "#EEE" : "#FFF"
          }),
          maxBodyHeight: "450px",
          minBodyHeight: "370px",
          headerStyle: {
            backgroundColor: "#358600",
            color: "#fff",
            whiteSpace: "nowrap"
          },
          cellStyle: {
            whiteSpace: "nowrap"
          },
          padding: "dense",
          toolbar: false
        }}
        components={{
          Toolbar: (props) => <MTableToolbar {...props} />
        }}
        onSelectionChange={(rows) => {
          setSelectedData(rows);
        }}
        localization={{
          body: {
            emptyDataSourceMessage: `${t("general.emptyDataMessageTable")}`
          }
        }}
      />
      <TablePagination
        align="left"
        className="px-16"
        rowsPerPageOptions={[1, 2, 3, 5, 10, 25, 50, 100]}
        component="div"
        labelRowsPerPage={t("general.rows_per_page")}
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("general.of")} ${count !== -1 ? count : `more than ${to}`}`
        }
        count={totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "Previous Page"
        }}
        nextIconButtonProps={{
          "aria-label": "Next Page"
        }}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(e.target.value);
          setPage(0);
        }}
      />

      {isOpenDialog && (
        <SampleDialog
          open={isOpenDialog}
          handleClose={handleCloseDialog}
          itemEdit={itemEdit}
          sampleType={type}
          isView={isView}
          isEdit={isEdit}
        />
      )}
      {isOpenComfirmDialog && (
        <ConfirmationDialog
          open={isOpenComfirmDialog}
          onConfirmDialogClose={() => setIsOpenComfirmDialog(false)}
          onYesClick={handleDelete}
          title={t("confirm")}
          text={t("DeleteAllConfirm")}
          Yes={t("general.Yes")}
          No={t("general.No")}
        />
      )}
      {isOpenImportExcel && (
        <UploadFormPopupCustom
          t={t}
          handleClose={() => setIsOpenImportExcel(false)}
          open={isOpenImportExcel}
          handleOKEditClose={() => setIsOpenImportExcel(false)}
          acceptType="xlsm;xls;xlsx"
          uploadUrl={ConstantList.API_ENPOINT + "/api/upload-excel/sample-bag"}
        />
      )}
    </div>
  );
}

export default Sample;
