import React, { useEffect, useState } from "react";
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  Icon,
  IconButton,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import PaperComponent from "app/views/Component/Paper/PaperCompnent";
import TabPanel from "app/views/Component/TabPanel/TabPanel";
import { useTranslation } from "react-i18next";
import { ValidatorForm } from "react-material-ui-form-validator";
import SampleInfomation from "./SampleInfomation";
import SampleCharacteristic from "./SampleCharacteristic";
import SampleSplitTube from "./SampleSplitTube";
import { FIELDS, INIT_SAMPLE, OBJECT_TYPE, SAMPLE_DIALOG_TABS } from "../constant/SampleConst";
import { addYearsToDate } from "app/utils/common";
import { toast } from "react-toastify";
import { addSample, getTargetOther, updateSample } from "../services/SampleServices";
import { SEARCH_OBJECT_MAX_SIZE, STATUS_REQUESTS } from "app/appContansts";
import { convertDataSubmit, convertDataUi } from "../utils/SampleUtils";
import { searchReagent } from "app/views/Reagent/ReagentService";
import AddTargetOtherDialog from "./AddTargetOtherDialog";
import ReagentEditorDialog from "app/views/Reagent/ReagentEditorDialog";

function SampleDialog(props) {
  const { open, handleClose, itemEdit, sampleType, isView, isEdit } = props;
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [dataForm, setDataForm] = useState(INIT_SAMPLE);
  const [biologics, setBiologics] = useState([]);
  const [dataOtherTargets, setDataOtherTargets] = useState([]);
  const [shouldOpenAddTargetOther, setShouldOpenAddTargetOther] = useState(false);
  const [shouldOpenAddOtherDialog, setShouldOpenAddOtherDialog] = useState(false);

  useEffect(() => {
    getOtherTargets();
    getBiologic();
  }, []);

  useEffect(() => {
    if (itemEdit?.id) {
      setDataForm(convertDataUi(itemEdit));
    }
  }, [itemEdit]);

  const getBiologic = async () => {
    try {
      const { data } = await searchReagent(SEARCH_OBJECT_MAX_SIZE);
      if (data?.code === STATUS_REQUESTS.success) {
        setBiologics(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const getOtherTargets = async () => {
    try {
      const { data } = await getTargetOther(OBJECT_TYPE.TARGET);
      if (data?.code === STATUS_REQUESTS.success) {
        setDataOtherTargets(data?.data);
      } else toast.error(t("error_message"));
    } catch (error) {
      toast.error(t("error_message"));
    }
  }

  const handleChangeTab = (_, newTab) => {
    let fieldCheck = [
      dataForm?.sampleCode,
      dataForm?.provinceName,
      dataForm?.samplingDate,
      dataForm?.target,
      dataForm?.dateOfTest,
      dataForm?.dateOfReceive,
      dataForm?.sampleTypeOther || dataForm?.sampleType
    ];

    let checkTabValid = fieldCheck.some((item) => !item);
    if ((newTab === SAMPLE_DIALOG_TABS.PROPERTIES || newTab === SAMPLE_DIALOG_TABS.SPLIT_TUBE) && checkTabValid) {
      return toast.warning(t("toast.please_enter_full"));
    }
    setActiveTab(newTab);
  };

  const handleFormSubmit = async () => {
    try {
      let dataSubmit = convertDataSubmit(dataForm, sampleType);
      if (new Date(dataSubmit.customerDateOfBirth) >= new Date(dataSubmit.samplingDate)) {
        toast.warn(t("sample.dateOfBirthErr"));
        return;
      }

      if (new Date(dataSubmit.samplingDate) >= new Date(dataSubmit.dateOfReceive)) {
        toast.warn(t("sample.SamplingDateErr"));
        return;
      }

      const { data } = await (dataForm?.id ? updateSample(dataSubmit) : addSample(dataSubmit));
      if (data?.code === STATUS_REQUESTS.success) {
        let message = dataForm?.id ? t("toast.edit_success") : t("toast.add_success");
        toast.success(message);
        handleClose();
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDataForm({ ...dataForm, [name]: value });
  };

  const handleChangeWithName = (name, value) => {
    switch (name) {
      case FIELDS.SAMPLE_SEPARATION_DATE:
        setDataForm({ ...dataForm, [name]: value, expiryDate: addYearsToDate(value, 3) });
        break;
      case FIELDS.CABINET_OBJ:
        setDataForm({ ...dataForm, [name]: value, rackObj: null, box: null });
        break;
      case FIELDS.RACK_OBJ:
        setDataForm({ ...dataForm, [name]: value, box: null });
        break;
      case FIELDS.BOX_EDIT:
        setDataForm({
          ...dataForm,
          box: value,
          cabinetObj: value?.slot?.rack?.cabinet || null,
          rackObj: value?.slot?.rack || null
        });
        break;
      default:
        setDataForm({ ...dataForm, [name]: value });
    }
  };

  return (
    <Dialog open={open} PaperComponent={PaperComponent} maxWidth="lg" fullWidth={true}>
      <DialogTitle className="cursor-move">
        <div className="flex flex-middle flex-space-between">
          <span className="mb-20 styleColor">{itemEdit?.id ? t("update") : t("Add")}</span>
          <IconButton onClick={() => handleClose()}>
            <Icon color="error" title={t("close")}>
              close
            </Icon>
          </IconButton>
        </div>
      </DialogTitle>
      <ValidatorForm onSubmit={handleFormSubmit}>
        <DialogContent dividers>
          <AppBar position="static" color="default">
            <Tabs
              orientation="horizontal"
              value={activeTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              <Tab label={t("sampleBag.information")} />
              <Tab label={t("sampleBag.properties")} />
              <Tab label={t("sampleBag.splitTube")} />
            </Tabs>
          </AppBar>
          <TabPanel value={activeTab} index={SAMPLE_DIALOG_TABS.INFOMATION} color="#ffffff" className="max-h-400">
            <SampleInfomation
              t={t}
              data={dataForm}
              handleChange={handleChange}
              handleChangeWithName={handleChangeWithName}
              isView={isView}
              getOtherTargets={getOtherTargets}
              setShouldOpenAddTargetOther={setShouldOpenAddTargetOther}
              dataOtherTargets={dataOtherTargets}
              shouldOpenAddTargetOther={shouldOpenAddTargetOther}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={SAMPLE_DIALOG_TABS.PROPERTIES} color="#ffffff" className="max-h-400">
            <SampleCharacteristic
              data={dataForm}
              handleChangeWithName={handleChangeWithName}
              biologics={biologics}
              isView={isView}
              getBiologic={getBiologic}
              shouldOpenAddOtherDialog={shouldOpenAddOtherDialog}
              setShouldOpenAddOtherDialog={setShouldOpenAddOtherDialog}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={SAMPLE_DIALOG_TABS.SPLIT_TUBE} color="#ffffff" className="max-h-400">
            <SampleSplitTube
              t={t}
              data={dataForm}
              handleChange={handleChange}
              handleChangeWithName={handleChangeWithName}
              isView={isView}
              editMode={isEdit}
            />
          </TabPanel>
        </DialogContent>

        <DialogActions spacing={4} className="flex flex-end flex-middle">
          <Button variant="contained" color="secondary" onClick={() => handleClose()}>
            {t("Cancel")}
          </Button>
          {!isView && (
            <Button variant="contained" color="primary" type="submit">
              {t("Save")}
            </Button>
          )}
        </DialogActions>
      </ValidatorForm>

      {shouldOpenAddTargetOther &&
        <AddTargetOtherDialog
          handleClose={() => setShouldOpenAddTargetOther(false)}
          t={t}
          handleSubmitData={(data) => handleChangeWithName("target", data)}
          handleGetOtherTargets={getOtherTargets}
        />}

      {
        shouldOpenAddOtherDialog &&
        <ReagentEditorDialog
          t={t}
          handleClose={() => setShouldOpenAddOtherDialog(false)}
          open={true}
          handleOKEditClose={() => {
            getBiologic();
            setShouldOpenAddOtherDialog(false)
          }}
          item={null}
        />
      }
    </Dialog>
  );
}

export default SampleDialog;
