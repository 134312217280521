/*TODO format code, xóa consolog, comment thừa, xử lý bestpractise về REACT JS và java script*/
import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/serum-tube";
const API_PATH_EXPORT_INVOICE_ITEM = ConstantList.API_ENPOINT + "/api/export-invoice-item";
const API_PATH_LAB_TEST_RESULT_DETAIL = ConstantList.API_ENPOINT + "/api/lab-test-result-detail";
const API_PATH_EXPORT_EXCEL = ConstantList.API_ENPOINT + "/api/download-excel";

export const exportExcel = (ids) => {
  const url = API_PATH_EXPORT_EXCEL + "/serum-tube";

  return axios({
    url: url,
    method: "POST",
    responseType: "blob",
    data: ids,
  });
};

export const exportToExcelBox = (id) => {
  const url = API_PATH_EXPORT_EXCEL + "/export-excel-tube-position/" + id;
  return axios.get(url, { responseType: "blob" });
};

export const searchByPage = (searchObject) => {
  const url = API_PATH + "/search";
  return axios.post(url, searchObject);
};

export const getItemById = (id) => {
  const url = API_PATH + "/" + id;
  return axios.get(url);
};

export const deleteItem = (id) => {
  const url = API_PATH + "/" + id;
  return axios.delete(url);
};

export const addNew = (item) => {
  const url = API_PATH;
  return axios.post(url, item);
};

export const update = (item) => {
  const url = API_PATH + "/" + item.id;
  return axios.put(url, item);
};

export const checkCode = (id, code) => {
  const config = { params: { id: id, code: code } };
  const url = API_PATH + "/check-code";
  return axios.get(url, config);
};

export const getByBoxId = (id) => {
  const url = API_PATH + "/get-by-box-id/" + id;
  return axios.get(url);
};

export const getBySerumTube = (id) => {
  const url = API_PATH + "/get-by-serum-tube/" + id;
  return axios.get(url);
};

export const getLabTestResultDetailBySerumTube = (id) => {
  const url = API_PATH_LAB_TEST_RESULT_DETAIL + "/get-by-serum-tube-id/" + id;

  return axios.get(url);
};

export const checkCodeSerumtuBe = (id, code) => {
  const config = { params: { id: id, code: code } };
  const url = API_PATH_EXPORT_INVOICE_ITEM + "/check-code";
  return axios.get(url, config);
};
