import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const ImageViewer = ({ image }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="image-reviewer">
      <img
        src={image}
        alt="Thumbnail"
        onClick={handleClickOpen}
        className="review-image"
      />

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle className="position-relative">
          {t("imagePreview")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="position-absolute t-8 r-8"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={image}
            alt="Full Size"
            className="w-100"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageViewer;
