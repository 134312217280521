import shortid from "shortid";

export const checkRoleExist = (roles, roleChecks) => {
  return roles?.some((role) => roleChecks.includes(role?.authority));
};

export const generateId = (ids = []) => {
  let newId = shortid.generate();

  while (ids.includes(newId)) {
    newId = shortid.generate();
  }

  return newId;
};

export const addYearsToDate = (date, yearsAdd) => {
  let newDate = new Date(date);
  let currentYear = newDate.getFullYear();
  let newYear = currentYear + yearsAdd;
  newDate.setFullYear(newYear);

  return newDate;
};
