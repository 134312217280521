import React from "react";
import ConstantList from "../../appConfig";
import Sample from "./Sample";
import { SAMPLE_SEARCH_CODES } from "./constant/SampleConst";

const SampleRoutes = [
  {
    path: ConstantList.ROOT_PATH + "sample-diagnose",
    exact: true,
    component: () => <Sample type={SAMPLE_SEARCH_CODES.DIAGNOSTIC_SAMPLE} />
  },
  {
    path: ConstantList.ROOT_PATH + "sample-evaluate_methods",
    exact: true,
    component: () => <Sample type={SAMPLE_SEARCH_CODES.METHOD_EVALUATION_SAMPLE} />
  },
  {
    path: ConstantList.ROOT_PATH + "sample-used-for-production",
    exact: true,
    component: () => <Sample type={SAMPLE_SEARCH_CODES.PRODUCTION_SAMPLE} />
  },
  {
    path: ConstantList.ROOT_PATH + "sample-research",
    exact: true,
    component: () => <Sample type={SAMPLE_SEARCH_CODES.RESEARCH_SAMPLE} />
  },
];

export default SampleRoutes;
