import axios from "axios";
import ConstantList from "../../appConfig";
const API_PATH = ConstantList.API_ENPOINT + "/api/box";
const API_PATH_EXPORT_EXCEL = ConstantList.API_ENPOINT + "/api/download-excel";

export const exportBoxLabel = () => {
  const url = API_PATH_EXPORT_EXCEL + "/box-label";

  return axios({
    url: url,
    method: "GET",
    responseType: "blob",
  });
}

export const searchByPage = (searchObject) => {
  var url = API_PATH + "/search";
  return axios.post(url, searchObject);
};

export const getByRackId = id => {
  var url = API_PATH + "/get-by-rack-id/" + id;
  return axios.get(url);
};

export const getItemById = id => {
  var url = API_PATH + "/" + id;
  return axios.get(url);
};
export const deleteItem = id => {
  var url = API_PATH + "/" + id;
  return axios.delete(url);
};
export const addNew = item => {
  var url = API_PATH;
  return axios.post(url, item);
};
export const update = item => {
  var url = API_PATH + "/" + item.id;
  return axios.put(url, item);
};
export const checkCode = (id, code) => {
  const config = { params: { id: id, code: code } };
  var url = API_PATH + "/check-code";
  return axios.get(url, config);
};

export const listBoxPosition = id => {
  var url = API_PATH + "/list-box-position/" + id;
  return axios.get(url);
}

export const getByCode = code => {
  var url = API_PATH + "/get-by-code";
  return axios.post(url,{code:code});
}

export const getAllBoxPosition = id => {
  var url = API_PATH + "/get-all-box-position/" + id;
  return axios.get(url);
}