import React, { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import { IconButton, Icon } from "@material-ui/core";

function AutocompleteOther(props) {
  const {
    label,
    validators = [],
    errorMessages = [],
    options = [],
    getOptionLabel = (option) => option,
    handleChange,
    name,
    value,
    nameOrther,
    valueOrther,
    handleChangeCustom,
    disabled,
    handleOther,
    getOptionSelected,
    handleConvertValue
  } = props;

  const [isOther, setIsOther] = useState(props?.isOther || false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    if (valueOrther) {
      setIsOther(true);
    }
  }, [valueOrther]);

  useEffect(() => {
    if (options && handleConvertValue) {
      setCurrentValue(handleConvertValue(value));
      return;
    }
    setCurrentValue(value);
  }, [options, value]);

  useEffect(() => {
    setIsOther(props?.isOther || false);
  }, [props?.isOther]);

  const handleSwitch = () => {
    setIsOther(false);
    handleChange && handleChange(nameOrther, null);
    handleChangeCustom && handleChangeCustom(nameOrther, null);
  };

  const handleDoubleClick = () => {
    setIsOther(true);
    handleChange && handleChange(name, null);
    handleChangeCustom && handleChangeCustom(name, null);
    handleOther && handleOther();
  };

  return (
    <>
      {!isOther ? (
        <Autocomplete
          onDoubleClick={handleDoubleClick}
          size="small"
          options={options}
          className="flex-end"
          clearable
          getOptionLabel={getOptionLabel}
          getOptionSelected={(option, value) => { getOptionSelected && getOptionSelected(option, value) }}
          onChange={(_, newValue) => {
            handleChange && handleChange(name, newValue);
            handleChangeCustom && handleChangeCustom(name, newValue);
          }}
          value={currentValue}
          renderInput={(params) => (
            <TextValidator
              {...params}
              label={label}
              value={value}
              variant="outlined"
              validators={!isOther ? validators : []}
              errorMessages={!isOther ? errorMessages : []}
            />
          )}
          disabled={disabled}
        />
      ) : (
        <TextValidator
          className="w-100"
          label={label}
          onChange={(e) => {
            handleChange && handleChange(nameOrther, e.target.value);
            handleChangeCustom && handleChangeCustom(nameOrther, e.target.value);
          }}
          type="text"
          value={valueOrther}
          variant="outlined"
          size="small"
          validators={isOther ? validators : []}
          errorMessages={isOther ? errorMessages : []}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton size="small" onClick={handleSwitch} disabled={disabled}>
                  <Icon>swap_horiz</Icon>
                </IconButton>
              </InputAdornment>
            )
          }}
          disabled={disabled}
        />
      )}
    </>
  );
}

export default AutocompleteOther;
