import { EgretLoadable } from "egret";
import { withTranslation } from "react-i18next";
import ConstantList from "../../appConfig";
const LTR = EgretLoadable({
  loader: () => import("./LabTestResult"),
});
const ViewComponent = withTranslation()(LTR);

const LTRRoutes = [
  {
    path: ConstantList.ROOT_PATH + "lab-test-result",
    exact: true,
    component: ViewComponent,
  },
];

export default LTRRoutes;
