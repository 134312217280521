import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, IconButton } from "@material-ui/core";
import { Button } from "material-ui";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { addTargetOther } from "../services/SampleServices";
import { toast } from "react-toastify";
import { STATUS_REQUESTS } from "app/appContansts";
import LabelRequired from "app/views/Component/LabelRequired";

const AddTargetOtherDialog = (props) => {
    const { t, handleClose, handleSubmitData, handleGetOtherTargets } = props;
    const [dataForm, setDataForm] = useState({
        name: "",
        code: "",
        description: "",
        type: 1
    });

    const handleFormSubmit = async () => {
        try {
            const { data } = await addTargetOther(dataForm);
            if (data?.code === STATUS_REQUESTS.success) {
                handleSubmitData && handleSubmitData(data?.data);
                handleGetOtherTargets && handleGetOtherTargets();
                handleClose();
            } else {
                toast.error(t("error_message"));
            }
        } catch {
            toast.error(t("error_message"));
        }
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setDataForm({
            ...dataForm,
            [name]: value
        })
    }

    return (
        <Dialog
            open={true}
            maxWidth={"sm"}
            fullWidth={true}
        >
            <DialogTitle>
                <span className="mb-20 styleColor">
                    {t("sampleBag.addObject")}
                </span>
                <IconButton
                    className="position-absolute t-10 r-10"
                    onClick={() => handleClose()}
                >
                    <Icon color="error" title={t("close")}>
                        close
                    </Icon>
                </IconButton>
            </DialogTitle>
            <ValidatorForm
                onSubmit={handleFormSubmit}
            >
                <DialogContent dividers>
                    <Grid className="" container spacing={4}>
                        <Grid item sm={12} xs={12}>
                            <TextValidator
                                className="w-100 mb-16"
                                onChange={(e) => handleChange(e)}
                                type="text"
                                label={t("Code")}
                                name="code"
                                value={dataForm?.code}
                                variant="outlined"
                                size="small"
                            />
                            <TextValidator
                                className="w-100 mb-16"
                                label={<LabelRequired label={t("Name")} isRequired />}
                                onChange={(e) => handleChange(e)}
                                type="text"
                                name="name"
                                value={dataForm?.name}
                                variant="outlined"
                                validators={["required"]}
                                errorMessages={[t("Validation.required")]}
                                size="small"
                            />
                            <TextValidator
                                className="w-100 mb-16"
                                onChange={(e) => handleChange(e)}
                                type="text"
                                label={t("Description")}
                                name="description"
                                value={dataForm?.description}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions spacing={4} className="flex flex-end flex-middle">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => props.handleClose()}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        {t("Save")}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    )
}

export default AddTargetOtherDialog;