import React, { useEffect, useState } from "react";
import { Grid, Button, Checkbox } from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import LabelRequired from "app/views/Component/LabelRequired";
import { Autocomplete } from "@material-ui/lab";
import SelectBoxDialog from "./SelectBoxDialog";
import { toast } from "react-toastify";
import { BLOB_TYPE, SEARCH_OBJECT_MAX_SIZE, STATUS_REQUESTS } from "app/appContansts";
import { searchByPage as searchCabinet } from "app/views/Cabinet/CabinetService";
import { searchByPage as searchRack } from "app/views/Rack/RackService";
import { ValidatedDatePicker } from "app/views/Component/CustomValidateComponent";
import { BOX_TYPES } from "../constant/SampleConst";
import { convertSelectBoxPosition } from "../utils/SampleUtils";
import { getByCode } from "app/views/Box/BoxService";
import { exportToExcelBox } from "app/views/SerumTube/SerumTubeService";
import { saveAs } from "file-saver";

function SampleSplitTube(props) {
  const { data, t, handleChange, handleChangeWithName, isView, editMode } = props;
  const [isOpenSelectBox, setIsOpenSelectBox] = useState(false);

  const [cabinets, setCabinets] = useState([]);
  const [racks, setRacks] = useState([]);
  const [isEdit, setIsEdit] = useState(isView);

  useEffect(() => {
    if (data?.id) {
      getBoxByCode(data?.serumTubes[0]?.boxCode);
    }
    editMode && setIsEdit(data?.id && data?.serumTubes?.length > 0);
  }, [data?.id]);

  useEffect(() => {
    getCabinets();
  }, []);

  const getCabinets = async () => {
    try {
      const { data } = await searchCabinet(SEARCH_OBJECT_MAX_SIZE);
      if (data.code === STATUS_REQUESTS.success) {
        setCabinets(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const getRacks = async (cabinetId) => {
    try {
      const { data } = await searchRack({ ...SEARCH_OBJECT_MAX_SIZE, cabinetId });
      if (data.code === STATUS_REQUESTS.success) {
        setRacks(data?.data?.content || []);
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const getBoxByCode = async (code) => {
    try {
      const { data } = await getByCode(code);
      if (data?.code === STATUS_REQUESTS.success) {
        handleChangeWithName("boxEdit", data?.data);
      }
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  const handleOpenSelectBox = () => {
    if (!data?.rackObj && !data?.cabinetObj) return toast.warning(t("toast.please_select_cabinet_rack"));
    setIsOpenSelectBox(true);
  };

  const handleSaveBox = (box) => {
    handleChangeWithName("box", box);
    setIsOpenSelectBox(false);
  };

  const handleSelectBoxPositions = (matrix, row, column) => {
    let fieldChecks = [
      data?.sampleSeparationDate,
      data?.freezeDate,
      data?.expiryDate,
      data?.tubeQuantity,
      data?.maxVolume
    ];

    if (fieldChecks.some((item) => !item)) return toast.warning(t("toast.please_enter_full"));

    let maxPosiion = (data?.box?.boxPositions || []).filter((item) => !item?.serumTube)?.length || 0;
    if (Number(data?.tubeSplit) > maxPosiion) return toast.warning(t("toast.box_full"));

    let dataSelect = [...getSelectPostionBox(matrix, row, column)];
    let checkPositionValid = dataSelect.some((item) => item?.serumTube);

    if (dataSelect.length !== Number(data?.tubeSplit) || checkPositionValid) {
      return toast.warning(t("toast.position_invalid"));
    }

    handleChangeWithName("serumTubes", convertSelectBoxPosition(data, dataSelect));
  };

  const getSelectPostionBox = (matrix, row, column, dataSelect = []) => {
    let amountTube = Number(data?.tubeSplit) || 0;
    if (matrix[row]) {
      for (let i = column; i < matrix[row]?.length; i++) {
        if (dataSelect.length < amountTube) {
          dataSelect.push(matrix[row][i]);
        }
      }

      if (dataSelect.length < amountTube) {
        getSelectPostionBox(matrix, row + 1, 0, dataSelect);
      }
    }

    return dataSelect;
  };

  const checkPositionActive = (dataCheck) => {
    let idList = (data?.serumTubes || []).map((item) => item?.id);
    return idList.includes(dataCheck?.id);
  };

  const renderBoxPosition = () => {
    let boxPositions = data?.box?.boxPositions || [];
    let maxRow = Math.max(...boxPositions.map((item) => item?.rowIndex));
    let maxColumn = Math.max(...boxPositions.map((item) => item?.columnIndex));

    const matrix = Array.from({ length: maxRow }, () => Array.from({ length: maxColumn }, () => null));
    boxPositions.forEach((item) => {
      matrix[item.rowIndex - 1][item.columnIndex - 1] = item;
    });

    const rows = [];
    //Render header từ 1 đến maxColumn
    const columnHeaders = [];
    columnHeaders.push(<th key={-1}></th>);
    for (let i = 1; i <= maxColumn; i++) {
      columnHeaders.push(<th key={i}>{i}</th>);
    }
    rows.push(<tr>{columnHeaders}</tr>);

    //Render hàng với ô đầu tiên là chữ cái từ A-Z
    matrix.forEach((items, indexRow) => {
      let cells = [];
      cells.push(<th key={-1}>{String.fromCharCode(65 + indexRow)}</th>);
      items.forEach((item, index) => {
        cells.push(
          <td key={index} className="text-center">
            {item?.serumTube?.code || (
              <Checkbox
                checked={checkPositionActive(item)}
                onClick={() => handleSelectBoxPositions(matrix, indexRow, index)}
                disabled={isEdit}
              />
            )}
          </td>
        );
      });
      rows.push(<tr key={indexRow}>{cells}</tr>);
    });

    return rows;
  };

  const handleExportFlowChart = async () => {
    try {
      const res = await exportToExcelBox(data?.box?.id);
      toast.info(t("general.successExport"));
      let blob = new Blob([res?.data], {
        type: BLOB_TYPE.XLSX
      });
      saveAs(blob, "Vị_trí_ống.xlsx");
    } catch (error) {
      toast.error(t("error_message"));
    }
  };

  return (
    <div>
      <fieldset>
        <legend className="styleColor">{t("sample.split_buffy")}</legend>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.split_amount")} />}
              onChange={handleChange}
              type="number"
              name="temp"
              value={data?.temp}
              variant="outlined"
              size="small"
              disabled={isEdit}
            />
          </Grid>
        </Grid>
      </fieldset>
      <fieldset className="mt-12">
        <legend className="styleColor">{t("sample.split_serum")}</legend>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Autocomplete
              size="small"
              options={cabinets}
              className="flex-end"
              clearable
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                handleChangeWithName("cabinetObj", value);
                getRacks(value?.id);
              }}
              value={data?.cabinetObj}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label={<LabelRequired label={t("sample.select_freezer")} isRequired />}
                  value={data?.cabinetObj}
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={[t("Validation.required")]}
                />
              )}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Autocomplete
              size="small"
              options={racks}
              className="flex-end"
              clearable
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                handleChangeWithName("rackObj", value);
              }}
              value={data?.rackObj}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  label={<LabelRequired label={t("sample.select_rack")} isRequired />}
                  value={data?.rackObj}
                  variant="outlined"
                  validators={["required"]}
                  errorMessages={[t("Validation.required")]}
                />
              )}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} className="flex flex-middle">
            <div className="flex-1">
              <TextValidator
                className="w-100"
                label={<LabelRequired label={t("sample.select_box")} isRequired />}
                type="text"
                name="code"
                value={data?.box?.code || ""}
                variant="outlined"
                size="small"
                disabled
                validators={["required"]}
                errorMessages={[t("Validation.required")]}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className="ml-12"
              onClick={handleOpenSelectBox}
              disabled={isEdit}
            >
              {t("sample.select_box")}
            </Button>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.box_type")} />}
              type="text"
              name="boxResults"
              value={BOX_TYPES.find((item) => item.code === data?.box?.boxResults)?.name || ""}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.manager")} />}
              type="text"
              name="manager"
              value={data?.box?.manager || ""}
              variant="outlined"
              size="small"
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <ValidatedDatePicker
              size="small"
              className="w-100"
              label={<LabelRequired label={t("sample.date_freezer_box")} />}
              inputVariant="outlined"
              type="text"
              autoOk={true}
              name="freezeDate"
              format="dd/MM/yyyy"
              value={data?.box?.freezeDate || null}
              fullWidth
              invalidDateMessage={t("general.errorMessages_invalid_date")}
              disabled
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.split_amount")} isRequired />}
              onChange={handleChange}
              type="number"
              name="tubeSplit"
              value={data?.tubeSplit}
              variant="outlined"
              size="small"
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.volume_tube")} isRequired />}
              onChange={handleChange}
              type="number"
              name="maxVolume"
              value={data?.maxVolume}
              variant="outlined"
              size="small"
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <TextValidator
              className="w-100"
              label={<LabelRequired label={t("sample.quality_tube")} isRequired />}
              onChange={handleChange}
              type="text"
              name="tubeQuantity"
              value={data?.tubeQuantity}
              variant="outlined"
              size="small"
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <ValidatedDatePicker
              size="small"
              className="w-100"
              label={<LabelRequired label={t("sample.date_separate")} isRequired />}
              inputVariant="outlined"
              type="text"
              autoOk={true}
              name="sampleSeparationDate"
              format="dd/MM/yyyy"
              value={data?.sampleSeparationDate || null}
              onChange={(date) => handleChangeWithName("sampleSeparationDate", date)}
              fullWidth
              invalidDateMessage={t("general.errorMessages_invalid_date")}
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <ValidatedDatePicker
              size="small"
              className="w-100"
              label={<LabelRequired label={t("sample.date_freezer")} isRequired />}
              inputVariant="outlined"
              type="text"
              autoOk={true}
              name="freezeDate"
              format="dd/MM/yyyy"
              value={data?.freezeDate || null}
              onChange={(date) => handleChangeWithName("freezeDate", date)}
              fullWidth
              invalidDateMessage={t("general.errorMessages_invalid_date")}
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
              disabled={isEdit}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <ValidatedDatePicker
              size="small"
              className="w-100"
              label={<LabelRequired label={t("sample.date_expire")} isRequired />}
              inputVariant="outlined"
              type="text"
              autoOk={true}
              name="expiryDate"
              format="dd/MM/yyyy"
              value={data?.expiryDate || null}
              onChange={(date) => handleChangeWithName("expiryDate", date)}
              fullWidth
              invalidDateMessage={t("general.errorMessages_invalid_date")}
              validators={["required"]}
              errorMessages={[t("Validation.required")]}
              disabled
            />
          </Grid>
        </Grid>
        {data?.box?.boxPositions?.length > 0 && (
          <div className="w-100 mt-24">
            <div className="flex flex-middle mb-16">
              <Button variant="contained" color="primary" onClick={handleExportFlowChart}>
                {t("sample.export_flowchart")}
              </Button>
            </div>
            <table className="matrix-table">
              <tbody>{renderBoxPosition()}</tbody>
            </table>
          </div>
        )}
      </fieldset>
      {isOpenSelectBox && (
        <SelectBoxDialog
          open={isOpenSelectBox}
          handleClose={() => setIsOpenSelectBox(false)}
          rackId={data?.rackObj?.id}
          handleSaveData={handleSaveBox}
          boxData={data?.box}
        />
      )}
    </div>
  );
}

export default SampleSplitTube;
