import React from "react";

function LabelRequired({ label, isRequired }) {
  return (
    <span className="font">
      {isRequired && <span className="text-red"> * </span>}
      {label}
    </span>
  );
}

export default LabelRequired;
