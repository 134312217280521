import { EgretLoadable } from "egret";
import ConstantList from "../../appConfig";
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const HealthOrg = EgretLoadable({
  loader: () => import("./HealthOrg")
});
const ViewComponent = withTranslation()(HealthOrg);

const healthOrgRoutes = [
  {
    path:  ConstantList.ROOT_PATH+"dashboard/health_org",
    exact: true,
    component: ViewComponent
  }
];

export default healthOrgRoutes;
